import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getWarningHandleStore = s => s.remote.warningHandle;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};


export const formatData = (data = { results: [] }) => {

    const tableData = data.results.map((item, idx) => ({
        warnCode: item["warnCode"]["code"],
        warnName: item["warnCode"]["name"],
        processCode: item["processCode"]["code"],
        processName: item["processCode"]["name"],
        key: idx
    }));

    return {
        data: tableData,
        created: data.created
    };
};


function* getWarningHandle () {
    try {
        const store = yield select(getWarningHandleStore);
        const deviceList = yield select(getDeviceOptionStore);

        const params = yield getParams(store, deviceList);
        const res = yield fetch(queryingApi.warningHandle, params, "get");
        const { data, created } = yield formatData(res);

        yield put({
            type: types.REMOTE_WARNING_HANDLE_SUCCEEDED,
            data,
            created
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.REMOTE_WARNING_HANDLE_FAILED,
        });
    }
}


function* watchGetRemoteWarningHandle () {
    yield all({
        warningHandle: takeEvery(types.REMOTE_WARNING_HANDLE_REQUESTED, getWarningHandle),
    });
}

export { watchGetRemoteWarningHandle };
