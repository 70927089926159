import axios from "axios";
import host from "./http.config";

const authInstance = axios.create({
    baseURL: host,
    timeout: 10000,
    validateStatus (status) {
        return status >= 200 && status <= 500;
    },
});

authInstance.interceptors.response.use(
    res => {
        try {
            switch (res.status) {
                case 401:
                    return Promise.reject({ message: res.data.message });

                default:
                    return res;
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    error => Promise.reject(error),
);

export default authInstance;
