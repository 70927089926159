import React from "react";
import { Button } from "antd";


const BeianFooter = () => {

    const handleClick = () => {
        window.open("http://beian.miit.gov.cn");
    };


    return (
        <div className="beian-footer"><Button type="link" onClick={handleClick}>京ICP备2020036435号</Button></div>
    );
};


export default BeianFooter;
