import { combineReducers } from "redux";
import users from "./users";
import events from "./events";
import options from "./options";
import equip from "./equipment";
import querying from "./querying";
import remote from "./remote";
import auth from "./auth"

const reducers = combineReducers({
    users,
    events,
    options,
    equip,
    querying,
    remote,
    auth,
});

export default reducers;
