import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch, getAltDateDesc } from "../../../utils";
import { message } from "antd";

const getRealtimeStatusStore = s => s.querying.realtimeStatus;

const getParams = store => {
    return {
        page: store.page - 1,
        size: store.size,
        projectCode: store.device
    };
};

const isOnOff = status => status ? "开启" : "关闭";

const formatData = (data = {}) => {

    const res = data.content ? data.content.map(item => {
        return {
            ...item,
            device: item.deviceObject?.name,
            mode: item.mode?.name,
            fanStatus: isOnOff(item.fanStatus),
            airStatus: isOnOff(item.airStatus),
            valveStatus: isOnOff(item.valveStatus),
            meter1: !!item.deviceObject?.meter1? item.meter1 : "-",
            meter2: !!item.deviceObject?.meter2? item.meter2 : "-",
            altDate: getAltDateDesc(+item.altDate),
        };
    }) : [];

    return {
        data: res,
        total: data.totalElements,
    };
};

function* getRealtimeStatus () {
    try {
        const store = yield select(getRealtimeStatusStore);

        const params = yield getParams(store);
        const res = yield fetch(queryingApi.realtimeStatus, params, "get");
        const { data, total } = formatData(res);

        yield put({
            type: types.QUERYING_REALTIME_STATUS_SUCCEEDED,
            data,
            total
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.QUERYING_REALTIME_STATUS_FAILED,
        });
    }
}

function* watchGetQueryingRealtimeStatus () {
    yield all({
        realtimeStatus: takeEvery(types.QUERYING_REALTIME_STATUS_REQUESTED, getRealtimeStatus),
    });
}

export { watchGetQueryingRealtimeStatus };
