import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getElectricStore = s => s.remote.electric;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};

export const formatData = (data = {}) => {
    return {
        "primaryMeter": data["primary_meter"] || null,
        "subMeter": data["sub_meter"] || null,
        created: data.created || null
    };
};


function* getElectric () {
    try {
        const store = yield select(getElectricStore);
        const deviceList = yield select(getDeviceOptionStore);

        const params = yield getParams(store, deviceList);
        let data = yield fetch(queryingApi.electric, params, "get");
        data = yield formatData(data);

        yield put({
            type: types.REMOTE_ELECTRIC_METER_SUCCEEDED,
            data
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.REMOTE_ELECTRIC_METER_FAILED,
        });
    }
}

function* watchGetRemoteElectric () {
    yield all({
        electricMeter: takeEvery(types.REMOTE_ELECTRIC_METER_REQUESTED, getElectric),
    });
}

export { watchGetRemoteElectric };
