import * as types from "../../action-types";
import produce from "immer";

const init = {
    data: [],
    loading: false,
    page: 1,
    size: 20,
    total: 0,
    projectCode: null,
};

const realtimeStatus = produce((draft = init, action) => {
    switch (action.type) {
        case types.QUERYING_REALTIME_STATUS_REQUESTED:
            draft.data = [];
            draft.loading = true;
            draft.total = 0;

            return draft;

        case types.QUERYING_REALTIME_STATUS_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;
            draft.total = action.total;

            return draft;

        case types.QUERYING_REALTIME_STATUS_FAILED:
            draft.loading = false;
            draft.data = [];

            return draft;

        case types.QUERYING_REALTIME_STATUS_UPDATE_PAGE:
            draft.page = action.page;
            draft.size = action.size;

            return draft;

        case types.QUERYING_REALTIME_STATUS_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.QUERYING_REALTIME_STATUS_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default realtimeStatus;
