import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: [],
    loading: false,
    total: 0,
    page: 1,
    size: 10,
    date: null,
    warningCode: null,
    processingCode: null,
    name: null
};

const eventsHandling = produce((draft = init, action) => {
    switch (action.type) {
        case types.EVENTS_HANDLING_REQUESTED:
            draft.data = [];
            draft.loading = true;
            draft.total = 0;

            return draft;

        case types.EVENTS_HANDLING_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;
            draft.total = action.total;

            return draft;

        case types.EVENTS_HANDLING_FAILED:
            draft.loading = false;
            draft.data = [];
            draft.total = 0;

            return draft;

        case types.EVENTS_HANDLING_UPDATE_PAGE:
            draft.page = action.page;
            draft.size = action.size;

            return draft;

        case types.EVENTS_HANDLING_UPDATE_PARAMS:
            draft.date = action.payload.date;
            draft.warningCode = action.payload.warningCode;
            draft.processingCode = action.payload.processingCode;
            draft.name = action.payload.name;

            return draft;

        case types.EVENTS_HANDLING_CLEAR_DATA:

            draft = {
                ...init
            };

            return draft;

        default:
            return draft;
    }
});

export default eventsHandling;
