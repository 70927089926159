import React from "react";
import LoginForm from "../components/login/LoginForm";
import BeianFooter from "../components/BeianFooter";


const Login = () => {

    return (
        <div className="login">
            <LoginForm className="login-form" />
            <BeianFooter />
        </div>
    );
};


export default Login;
