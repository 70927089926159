import React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "../components/Loading";
import loadable from "@loadable/component";
import { requireAuth } from "../utils/requireAuth";
import Page404 from "../pages/Page404";

const RunningStatus = loadable(() => import ("../pages/querying/RunningStatus"), {
    callback: <Loading />
});
const RealtimeStatus = loadable(() => import ("../pages/querying/RealtimeStatus"), {
    callback: <Loading />
});
const HistoryStatus = loadable(() => import ("../pages/querying/HistoryStatus"), {
    callback: <Loading />
});
const PowerQuerying = loadable(() => import ("../pages/querying/PowerQuerying"), {
    callback: <Loading />
});

const QueryingRoute = () => {
    return (
        <Switch>
            <Route path="/querying/running" component={requireAuth(RunningStatus)} />
            <Route path="/querying/history-status" component={requireAuth(HistoryStatus)} />
            <Route path="/querying/realtime-status" component={requireAuth(RealtimeStatus)} />
            <Route path="/querying/power" component={requireAuth(PowerQuerying)} />
            <Route component={Page404} />
        </Switch>
    );
};


export default QueryingRoute;
