import * as types from "../action-types";

const auth = {
    update_auth_status (status, isAdmin) {
        return {
            type: types.UPDATE_AUTH_STATUS,
            status,
            isAdmin
        };
    },
    update_token ({token, company}) {
        return {
            type: types.UPDATE_AUTH_TOKEN,
            token,
            company
        };
    },
    log_out () {
        return {
            type: types.LOG_OUT
        };
    },
};

export default auth;
