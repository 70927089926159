import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getHistoryStatusStore = s => s.querying.historyStatus;

const getParams = store => {

    const format = "YYYY-MM-DD";

    return {
        params: {
            deviceCode: store.device,
            queryDateFrom: store.date ? store.date[0].format(format) : null,
            queryDateTo: store.date ? store.date[1].format(format) : null,
        },
        page: store.page - 1,
        size: store.size
    };
};

const formatData = (data = {}) => {
    const res = data.content ? data.content.map(item => {
        return {
            ...item,
            device: item.deviceObject?.name,
            created: item.created.split(" ")[0]
        };
    }) : [];

    return {
        data: res,
        total: data.totalElements,
    };
};

function* getHistoryStatus () {
    try {
        const store = yield select(getHistoryStatusStore);
        const { params, page, size } = yield getParams(store);
        const res = yield fetch(`${queryingApi.historyStatus}?page=${page}&size=${size}`, params);
        const { data, total } = formatData(res);

        yield put({
            type: types.QUERYING_HISTORY_STATUS_SUCCEEDED,
            data,
            total
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.QUERYING_HISTORY_STATUS_FAILED,
        });
    }
}

function* watchGetQueryingHistoryStatus () {
    yield all({
        historyStatus: takeEvery(types.QUERYING_HISTORY_STATUS_REQUESTED, getHistoryStatus),
    });
}

export { watchGetQueryingHistoryStatus };
