import { all } from "redux-saga/effects";
import usersSaga from "./users";
import eventsSaga from "./events";
import optionsSaga from "./options";
import equipSaga from "./equipment";
import queryingSaga from "./querying";
import remoteSaga from "./remote";

export default function* rootSaga () {
    yield all({
        users: usersSaga(),
        events: eventsSaga(),
        options: optionsSaga(),
        equip: equipSaga(),
        querying: queryingSaga(),
        remote: remoteSaga(),
    });
}
