import { combineReducers } from "redux";
import cleaning from "./cleaning";
import alternative from "./alternative";
import electric from "./electricMeter";
import warningHandle from "./warningHandle";
import warningSetting from "./warningSetting";
import freshAir from "./freshAir";
import autoMode from "./autoMode";
import runningMode from "./runningMode";

const remote = combineReducers({
    alternative,
    cleaning,
    electric,
    warningHandle,
    warningSetting,
    freshAir,
    autoMode,
    runningMode,
});

export default remote;
