import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import actions from "../store/actions/auth";
import axios from "../api/authInstance";
import { authApi } from "../api/http.config";
import Loading from "../components/Loading";
import { formatCompany } from "./index";

export function requireAuth (Comp) {
    const mapState = s => {
        return {
            auth: s.auth,
        };
    };

    class AuthenticatedComponent extends Component {
        constructor (p) {
            super(p);

            this.state = {
                auth: false
            };
        }

        componentDidMount () {
            this.checkAuth();
        }

        checkAuth = () => {
            const { status } = this.props.auth;

            if (status) {
                return this.setState({
                    auth: true
                });
            }
            const token = sessionStorage.getItem("token") || "";
            const company = sessionStorage.getItem("company") || "";

            if (!token) {
                this.props.history.push("/login");
            }

            const _this = this;

            axios.post(
                authApi.validate, { token, company }
            ).then(res => {
                if (res.status !== 200 || !res.data.token) {
                    _this.props.history.push("/login");
                } else {
                    const pathname = location.pathname.split("/")[1];
                    const isAdmin = res.data.admin || false;
                    const company = res.data.company ? formatCompany(res.data.company) : [""];
                    _this.props.update_token({ token, company });
                    _this.props.update_auth_status(true, isAdmin);
                    this.setState({ auth: true });

                    if (!isAdmin && (pathname === "remote" || pathname === "project")) {
                        _this.props.history.push("/403");
                    }
                }
            }).catch((e) => {
                console.log(e);
                _this.props.history.push("/login");
            });
        };


        render () {
            const _this = this;
            const { auth } = this.state;
            return auth ? <Comp {..._this.props} /> : <Loading />;
        }
    }

    return withRouter(connect(mapState, actions)(AuthenticatedComponent));
}
