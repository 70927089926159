import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "../../api/authInstance";
import { authApi } from "../../api/http.config";
import { withRouter, useHistory } from "react-router-dom";
import icon from "../../static/icon.png";
import { connect } from "react-redux";
import actions from "../../store/actions/auth";
import { formatCompany } from "../../utils";

const { Item } = Form;

const LoginForm = props => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {

        const token = sessionStorage.getItem("token");
        const company = sessionStorage.getItem("company");

        if (token) {
            setLoading(true);
            axios.post(authApi.validate, { token, company }).then(res => {
                if (res.status === 200) {
                    props.update_token({ token, company: formatCompany(company) || [""] });
                    props.update_auth_status(true);
                    setLoading(false);
                    history.push("/");
                } else {
                    setLoading(false);
                }

            }).catch(() => {
                setLoading(false);
            });
        }

    }, []);

    useEffect(() => {
        if (props.user && props.pw) {
            onFinish({
                username: props.user,
                password: props.pw
            });
        }
    }, [props.user, props.pw]);

    const onFinish = values => {
        setLoading(true);
        if (values.remember) {
            window.localStorage.setItem("username", values.username);
            window.localStorage.setItem("remember", values.remember);
        }

        const params = {
            username: values.username,
            password: values.password
        };

        axios.post(authApi.auth, params)
            .then(res => {
                setLoading(false);
                if (res.status === 200) {
                    props.update_auth_status(true, res.data.admin);
                    props.update_token({ token: res.data.token, company: formatCompany(res.data.company) || [""] });
                    sessionStorage.setItem("token", res.data.token);
                    sessionStorage.setItem("username", values.username);
                    sessionStorage.setItem("company", res.data.company);
                    history.push("/");
                } else {
                    message.error(res.data.message);
                    history.push("/login");
                }

            })
            .catch(e => {
                setLoading(false);
                message.error(e.message);
                props.update_auth_status(false);
            });

    };

    const onForgotPwd = () => {
        console.log("click on forgot password btn.");
    };

    const onCheckbox = e => {
        const v = e.target.checked;
        if (!v) {
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("remember");
        }
    };

    useEffect(() => {
        const username = window.localStorage.getItem("username") || "";
        let remember = !!window.localStorage.getItem("remember");

        form.setFieldsValue({ username, remember });

    }, []);

    return (
        <Form
            name="login-form"
            onFinish={onFinish}
            className={props.className}
            form={form}
        >
            <img src={icon} alt="" className="login-icon" />
            <Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: "用户名不能为空",
                    },
                ]}
            >
                <Input disabled={loading} prefix={<UserOutlined />} placeholder="请输入用户名" />
            </Item>
            <Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: "密码不能为空",
                    },
                ]}
            >
                <Input.Password
                    disabled={loading}
                    prefix={<LockOutlined />}
                    placeholder="请输入密码"
                />
            </Item>
            <Item>
                <Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox onChange={onCheckbox} disabled={loading}>记住我</Checkbox>
                </Item>

                <Button disabled={loading} className="forgot-pwd-btn" type="link" onClick={onForgotPwd}>
                    忘记密码
                </Button>
            </Item>

            <Item>
                <Button loading={loading} className="login-btn" type="primary" htmlType="submit">
                    登陆
                </Button>
            </Item>
        </Form>
    );
};

export default connect(null, actions)(withRouter(LoginForm));
