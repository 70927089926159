import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getCleaningStore = s => s.remote.cleaning;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};

export const formatData = (data = {}) => {
    const arr = ["valve_close_time", "clean_last", "clean_humid", "clean_warn_time"];
    const bookingIdx = ["预约一", "预约二", "预约三", "预约四"];
    const timingData = {};
    const bookingData = [];
    arr.forEach(item => {
        timingData[item] = data[item];
    });

    data["clean_times"].forEach((item, idx) => {
        const obj = {};
        item["dayOfWeek"].forEach((day, index) => {
            obj[index] = day;
        });

        bookingData.push({
            ...obj,
            time: `${item.hour}:${item.minute < 10 ? "0" + item.minute : item.minute}`,
            key: idx,
            title: bookingIdx[idx]
        });
    });

    return {
        timingData,
        bookingData,
        created: data.created,
    };
};


function* getCleaning () {
    try {
        const store = yield select(getCleaningStore);
        const deviceList = yield select(getDeviceOptionStore);

        const params = yield getParams(store, deviceList);
        const data = yield fetch(queryingApi.cleaning, params, "get");
        const { timingData, bookingData, created } = yield formatData(data);

        yield put({
            type: types.REMOTE_CLEANING_SUCCEEDED,
            timingData,
            bookingData,
            created,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.REMOTE_CLEANING_FAILED,
        });
    }
}

function* watchGetRemoteCleaning () {
    yield all({
        cleaning: takeEvery(types.REMOTE_CLEANING_REQUESTED, getCleaning),
    });
}

export { watchGetRemoteCleaning };
