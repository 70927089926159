import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getFreshAirStore = s => s.remote.freshAir;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};

function* getFreshAir () {
    try {
        const store = yield select(getFreshAirStore);
        const deviceList = yield select(getDeviceOptionStore);

        const params = yield getParams(store, deviceList);
        let data = yield fetch(queryingApi.freshAir, params, "get");

        yield put({
            type: types.REMOTE_FRESH_AIR_SUCCEEDED,
            data
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.REMOTE_FRESH_AIR_FAILED,
        });
    }
}

function* watchGetRemoteFreshAir () {
    yield all({
        freshAir: takeEvery(types.REMOTE_FRESH_AIR_REQUESTED, getFreshAir),
    });
}

export { watchGetRemoteFreshAir };
