import React, { useState } from "react";
import { menuConfig } from "../config/nav";
import { useHistory } from "react-router-dom";
import { IdcardOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import icon from "../static/icon.png";
import ChangePwdBtn from "./profile/ChangePwdBtn";
import { getIconComp } from "../config/nav";
import Logout from "./profile/Logout";
import { connect } from "react-redux";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;


const SiderMenu = props => {
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);

    const handleCollapse = collapsed => setCollapsed(collapsed);
    const handleClick = item => {
        history.push(item.key);
    };

    return (
        <Sider
            width={200}
            style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
                paddingTop: 20
            }}
            breakpoint="lg"
            // collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
        >
            <div>
                {!collapsed && <img className="profile-icon" src={icon} alt="" />}
                {!collapsed && (
                    <div
                        style={{
                            display: "flex", flexDirection: "column",
                            color: "rgba(255, 255, 255, 0.65)",
                            padding: 4
                        }}
                    >
                        {
                            props.company.length && props.company.map((item, i) => {
                                return (
                                    <span key={i} style={{ margin: "0 auto" }}>{item}</span>
                                );
                            })
                        }

                    </div>
                )}

                <Menu
                    selectedKeys={[]}
                    theme="dark"
                    mode="vertical"
                >
                    <SubMenu
                        key="submenu"
                        title={
                            <div>
                                <span style={{ paddingLeft: collapsed ? 0 : 8 }}>
                                    <IdcardOutlined />
                                </span>
                                {
                                    !collapsed && <span>{sessionStorage.getItem("username")}</span>
                                }
                            </div>
                        }
                    >
                        <Item key="change-pwd">
                            <ChangePwdBtn />
                        </Item>
                        <Item key="log-out">
                            <Logout />
                        </Item>
                    </SubMenu>
                </Menu>
            </div>
            <Menu
                onClick={handleClick}
                theme="dark"
                mode="inline"
                style={{ height: "calc(100%-40px)", borderRight: 0 }}
                selectedKeys={window.location.pathname}
                defaultOpenKeys={[`/${window.location.pathname.split("/")[1]}`]}
            >
                {
                    Object.keys(menuConfig).map(key => {
                        if (!props.isAdmin && menuConfig[key].isAdmin) return null;

                        return menuConfig[key].children ? (
                            <SubMenu
                                key={menuConfig[key].path}
                                title={
                                    <>
                                        <span>{getIconComp(key)}</span>
                                        {
                                            !collapsed && <span>{menuConfig[key].title}</span>
                                        }

                                    </>
                                }
                            >
                                {
                                    Object.keys(menuConfig[key].children).map(item => {
                                        return (
                                            <Item
                                                key={menuConfig[key].children[item].path}
                                            >
                                                {menuConfig[key].children[item].title}
                                            </Item>
                                        );
                                    })
                                }
                            </SubMenu>
                        ) : (
                            <Item key={menuConfig[key].path}>
                                <span>{getIconComp(key)}</span>
                                {
                                    !collapsed && <span>{menuConfig[key].title}</span>
                                }

                            </Item>
                        );
                    })
                }
            </Menu>
        </Sider>
    );
};

const mapState = s => ({
    isAdmin: s.auth.isAdmin,
    company: s.auth.company
});

export default connect(mapState)(SiderMenu);
