import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { usersApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getUsersListStore = s => s.users.usersList;

const getParams = store => {
    return {
        size: store.pageSize,
        page: store.page,
        name: store.params.name,
        company: store.params.company,
        "project.id": store.params.project,
        "role.code": store.params.role
    };
};

const formatData = (data = {}) => {

    return {
        total: data.totalElements || 0,
        data: data.content.map(item => {
            return {
                ...item,
                password: "",
                project: item.project.id.toString(),
                role: item.role.code,
                roleName: item.role.name,
                company: item.company,
                projectName: item.project.name
            };
        })
    };
};

function* getUsersList () {
    try {
        const store = yield select(getUsersListStore);
        const params = yield getParams(store);
        let res = yield fetch(usersApi.user, params, "get");
        let { data, total } = yield formatData(res);

        yield put({
            type: types.USERS_LIST_SUCCEEDED,
            data,
            total,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.USERS_LIST_FAILED,
        });
    }
}

function* watchGetUsersList () {
    yield all({
        usersList: takeEvery(types.USERS_LIST_REQUESTED, getUsersList),
        updatePage: takeEvery(types.USERS_LIST_UPDATE_PAGE, getUsersList),
    });
}

export { watchGetUsersList };
