import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: [],
    loading: false,
    device: undefined,
    date: null,
    totalFee: 0,
    totalVolume: 0
};

const powerQuerying = produce((draft = init, action) => {
    switch (action.type) {
        case types.QUERYING_POWER_QUERYING_REQUESTED:
            draft.data = [];
            draft.loading = true;
            draft.totalFee = 0;
            draft.totalVolume = 0;

            return draft;

        case types.QUERYING_POWER_QUERYING_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;
            draft.totalFee = action.totalFee;
            draft.totalVolume = action.totalVolume;

            return draft;

        case types.QUERYING_POWER_QUERYING_FAILED:
            draft.loading = false;
            draft.data = [];

            return draft;

        case types.QUERYING_POWER_QUERYING_UPDATE_DATE:
            draft.date = action.date;

            return draft;

        case types.QUERYING_POWER_QUERYING_UPDATE_DEVICE:
            draft.device = action.device;

            return draft;

        case types.QUERYING_POWER_QUERYING_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default powerQuerying;
