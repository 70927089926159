import * as types from "../action-types";
import produce from "immer";


const init = {
    token: "",
    status: false,
    isAdmin: false,
    company:[""],
};

const options = produce((draft = init, action) => {
    switch (action.type) {
        case types.UPDATE_AUTH_STATUS:
            draft.status = action.status;
            draft.isAdmin = action.isAdmin;

            return draft;

        case types.UPDATE_AUTH_TOKEN:
            draft.token = action.token;
            draft.company = action.company;

            return draft;

        case types.LOG_OUT:
            draft.status = false;
            draft.token = "";
            draft.company = [""];

            return draft;

        default:
            return draft;
    }
});

export default options;
