import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getRunningModeStore = s => s.remote.runningMode;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};

export const formatData = data => {
    return {
        data: {
            runningMode: data.name,
            frequency: data.frequency
        },
        created: data.created,
    };
};

function* getRunningMode () {
    try {
        const store = yield select(getRunningModeStore);
        const deviceList = yield select(getDeviceOptionStore);

        const params = yield getParams(store, deviceList);
        const res = yield fetch(queryingApi.runningMode, params, "get");
        const { data, created } = formatData(res);

        yield put({
            type: types.REMOTE_RUNNING_MODE_SUCCEEDED,
            data,
            created
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.REMOTE_RUNNING_MODE_FAILED,
        });
    }
}

function* watchGetRemoteRunningMode () {
    yield all({
        autoMode: takeEvery(types.REMOTE_RUNNING_MODE_REQUESTED, getRunningMode),
    });
}

export { watchGetRemoteRunningMode };
