import React from "react";
import { Modal, Form, Input } from "antd";
import { getFormLayout } from "../../utils/form";

const ChangePwdModal = props => {
    const [form] = Form.useForm();
    const { onCancel, visible, onOk, loading, username } = props;

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                onOk(values);
                form.resetFields();
            })
            .catch(info => {
                console.log("表单验证失败：", info);
            });

    };

    return (
        <Modal
            title="修改密码"
            onCancel={handleCancel}
            onOk={handleOk}
            visible={visible}
            confirmLoading={loading}
        >
            <Form
                {...getFormLayout()}
                form={form}
                name="register"
                initialValues={{
                    username: username || sessionStorage.getItem("username")
                }}
            >
                <Form.Item
                    name="username"
                    label="用户名"
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="currentPassword"
                    label="当前密码"
                    rules={[
                        {
                            required: true,
                            message: "请输入当前密码",
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password disabled={loading} />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="新密码"
                    rules={[
                        {
                            required: true,
                            message: "请输入新密码",
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password disabled={loading} />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="确认新密码"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "确认密码不能为空",
                        },
                        ({ getFieldValue }) => ({
                            validator (rule, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("两次密码输入不一致！");
                            },
                        }),
                    ]}
                >
                    <Input.Password disabled={loading} />
                </Form.Item>
            </Form>
        </Modal>
    );
};


export default ChangePwdModal;
