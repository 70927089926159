import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getAlternativeStore = s => s.remote.alternative;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};

function* getAlternative () {
    try {
        const store = yield select(getAlternativeStore);
        const deviceList = yield select(getDeviceOptionStore);

        const params = yield getParams(store, deviceList);
        let data = yield fetch(queryingApi.alternative, params, "get");
        yield put({
            type: types.REMOTE_ALTERNATIVE_SUCCEEDED,
            data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.REMOTE_ALTERNATIVE_FAILED,
        });
    }
}

function* watchGetRemoteAlternative () {
    yield all({
        alternative: takeEvery(types.REMOTE_ALTERNATIVE_REQUESTED, getAlternative),
    });
}

export { watchGetRemoteAlternative };
