import React from "react";
import "./css/index.scss";
import { Layout } from "antd";
import SiderMenu from "./components/SiderMenu";
import MainRoute from "./route/MainRoute";

function App () {
    return (
        <Layout style={{ width: "100vw", minHeight: "100vh" }}>
            <Layout>
                <SiderMenu />
                <Layout style={{ marginLeft:200, padding: "0 24px 24px", height: "100vh" }}>
                    <MainRoute />
                </Layout>
            </Layout>
        </Layout>
    );
}

export default App;
