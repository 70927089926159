import {
    CarryOutOutlined,
    CloudServerOutlined,
    HddOutlined,
    UserOutlined,
    ProjectOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import React from "react";

export const menuConfig = {
    querying: {
        title: "监控中心",
        path: "/querying",
        children: {
            realtimeStatus: {
                title: "实时状态",
                path: "/querying/realtime-status"
            },
            historyStatus: {
                title: "历史状态",
                path: "/querying/history-status"
            },

            runningStatus: {
                title: "运行状态",
                path: "/querying/running"
            },
            powerQuerying: {
                title: "电量查询",
                path: "/querying/power"
            },
        }
    },
    equipment: {
        title: "局站管理",
        path: "/equipment",
        children: {
            equipmentList: {
                title: "局站列表",
                path: "/equipment/list"
            }
        }
    },    
    events: {
        title: "告警管理",
        path: "/events",
        children: {
            Dashboard: {
                title: "告警信息",
                path: "/events/handling"
            }
        }
    },

     remote: {
        title: "远程设置",
        path: "/remote",
        isAdmin: true,
        children: {
            realtime: {
                title: "远程查询",
                path: "/remote/querying"
            },
            warningHandle: {
                title: "告警控制",
                path: "/remote/warning-handle",
            },
            electricMeter: {
                title: "电表参数",
                path: "/remote/electric-meter",
            },
            warningSetting: {
                title: "告警参数",
                path: "/remote/warning-setting",
            },
            selfCleaning: {
                title: "自清洁参数",
                path: "/remote/cleaning",
            },
            alternative: {
                title: "轮替模式参数",
                path: "/remote/alternative",
            },
            freshAir: {
                title: "新风模式参数",
                path: "/remote/fresh-air",
            },
            autoMode: {
                title: "自动模式参数",
                path: "/remote/auto-mode",
            },
            runningMode: {
                title: "运行模式",
                path: "/remote/running-mode",
            },
        }
    },
   users: {
        title: "用户管理",
        path: "/users",
        children: {
            usersList: {
                title: "用户列表",
                path: "/users/list"
            }
        }
    },


        project: {
        isAdmin: true,
        title: "项目管理",
        path: "/project",
        children: {
            projectList: {
                title: "项目列表",
                path: "/project/list"
            }
        }
    }

};

export const getIconComp = str => {
    let node;
    switch (str) {
        case "events":
            node = (<CarryOutOutlined />);
            break;
        case "users":
            node = <UserOutlined />;
            break;
        case "equipment":
            node = <CloudServerOutlined />;
            break;
        case "querying":
            node = <HddOutlined />;
            break;
        case "project":
            node = <ProjectOutlined />;
            break;
        case "remote":
            node = <SettingOutlined />;
            break;
        default:
            node = null;
    }

    return node;
};
