import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getPowerQueryingStore = s => s.querying.powerQuerying;

const getParams = store => {

    const format = "YYYY-MM-DD";

    return {
        deviceCode: store.device,
        queryDateFrom: store.date ? store.date[0].format(format) : null,
        queryDateTo: store.date ? store.date[1].format(format) : null,
    };
};

const formatData = (data = {}) => {
    const res = data.dataList ? data.dataList.map((item, idx) => {
        return {
            device: item.deviceObject?.name,
            created: item.created.split(" ")[0],
            meter1Volume: item.meter1Volume,
            meter2Volume: item.meter2Volume,
            outTemp: item.outTemp,
            inTemp: item.inTemp,
            key: idx
        };
    }) : [];

    return {
        data: res,
        totalFee: data.totalFee,
        totalVolume: data.totalVolume,
    };
};

function* getPowerQuerying () {
    try {
        const store = yield select(getPowerQueryingStore);
        const params = yield getParams(store);
        const res = yield fetch(`${queryingApi.power}`, params);
        const { data, totalFee, totalVolume } = formatData(res);

        yield put({
            type: types.QUERYING_POWER_QUERYING_SUCCEEDED,
            data,
            totalFee,
            totalVolume
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.QUERYING_POWER_QUERYING_FAILED,
        });
    }
}

function* watchGetPowerQuerying () {
    yield all({
        powerQuerying: takeEvery(types.QUERYING_POWER_QUERYING_REQUESTED, getPowerQuerying),
    });
}

export { watchGetPowerQuerying };
