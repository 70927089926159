import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getRunningStatusStore = s => s.querying.runningStatus;

const getParams = store => {
    return {
        deviceCode: store.device,
        queryDate: store.date
    };
};


function* getRunningStatus () {
    try {
        const store = yield select(getRunningStatusStore);
        const params = yield getParams(store);
        let data = yield fetch(queryingApi.runningStatus, params, "post");
        yield put({
            type: types.QUERYING_RUNNING_SUCCEEDED,
            data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.QUERYING_RUNNING_FAILED,
        });
    }
}

function* watchGetQueryingRunning () {
    yield all({
        runningStatus: takeEvery(types.QUERYING_RUNNING_REQUESTED, getRunningStatus),
    });
}

export { watchGetQueryingRunning };
