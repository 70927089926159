import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { equipApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getEquipListStore = s => s.equip.equipList;

const getParams = store => {
    return {
        size: store.pageSize,
        page: store.page,
        company: store.params.company,
        name: store.params.name,
        "project.id": store.params.project
    };
};

const formatData = (data = {}) => {

    return {
        total: data.totalElements || 0,
        data: data.content.map(item => {
            return {
                ...item,
                project: item.project.id.toString(),
                projectName: item.project.name,
            };
        })
    };
};

function* getEquipList () {
    try {
        const store = yield select(getEquipListStore);
        const params = yield getParams(store);
        let res = yield fetch(equipApi.device, params, "get");
        let { data, total } = yield formatData(res);

        yield put({
            type: types.EQUIP_LIST_SUCCEEDED,
            data,
            total,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.EQUIP_LIST_FAILED,
        });
    }
}

function* watchGetEquipList () {
    yield all({
        equipList: takeEvery(types.EQUIP_LIST_REQUESTED, getEquipList),
        updatePage: takeEvery(types.EQUIP_LIST_UPDATE_PAGE, getEquipList),
    });
}

export { watchGetEquipList };
