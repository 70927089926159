import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: [],
    loading: false,
    device: undefined,
    created:"",
};

const warningHandle = produce((draft = init, action) => {
    switch (action.type) {
        case types.REMOTE_WARNING_HANDLE_REQUESTED:
            draft.data = [];
            draft.loading = true;
            draft.created = "";

            return draft;

        case types.REMOTE_WARNING_HANDLE_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;
            draft.created = action.created;

            return draft;

        case types.REMOTE_WARNING_HANDLE_FAILED:
            draft.loading = false;
            draft.data = [];

            return draft;

        case types.REMOTE_WARNING_HANDLE_UPDATE_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.REMOTE_WARNING_HANDLE_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default warningHandle;
