import { takeLeading, put, all } from "redux-saga/effects";
import * as types from "../action-types";
import { message } from "antd";
import { equipApi, optionsApi } from "../../api/http.config";
import { fetch } from "../../utils";

const formatData = (data = [], key = "code") => {
    const res = {};
    data.forEach(item => {
        res[item[key]] = item["name"];
    });
    return res;
};

const formatProjectData = (data = []) => {
    const res = {};
    data.forEach(item => {
        res[item["id"]] = {
            ...item
        };
    });
    return res;
};

const formatDeviceData = (data = []) => {
    const res = {};
    data.forEach(item => {
        res[item["id"]] = {
            name: item["name"],
            communicationCardNo: item["communicationCardNo"]
        };
    });

    return res;
};

function* getProjectList () {
    try {
        const res = yield fetch(optionsApi.project, { page: 0, size: 5000 }, "get");
        const data = yield formatData(res.content || [], "id");
        const parentData = yield formatProjectData(res.content);

        yield put({
            type: types.PROJECT_LIST_SUCCEEDED,
            data,
            parentData,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.PROJECT_LIST_FAILED,
        });
    }
}

function* getRoleList () {
    try {
        const res = yield fetch(optionsApi.role, {}, "get");
        const data = yield formatData(res, "code");

        yield put({
            type: types.ROLE_LIST_SUCCEEDED,
            data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.ROLE_LIST_FAILED,
        });
    }
}

function* getDeviceList () {
    try {
        const res = yield fetch(equipApi.device, { page: 0, size: 500 }, "get");
        const data = yield formatDeviceData(res.content);

        yield put({
            type: types.DEVICE_LIST_SUCCEEDED,
            data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.DEVICE_LIST_FAILED,
        });
    }
}

function* getProcessCode () {
    try {
        const res = yield fetch(optionsApi.processCode, {}, "get");

        const data = formatData(res, "code");
        yield put({
            type: types.PROCESS_CODE_SUCCEEDED,
            processCode: data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.PROCESS_CODE_FAILED,
        });
    }
}

function* getRunningModeCode () {
    try {
        const res = yield fetch(optionsApi.runningModeCode, {}, "get");
        const data = formatData(res, "code");

        yield put({
            type: types.RUNNING_MODE_CODE_SUCCEEDED,
            runningModeCode: data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.RUNNING_MODE_CODE_FAILED,
        });
    }
}

function* getEventsProcessCode () {
    try {
        const res = yield fetch(optionsApi.eventsProcessCode, {}, "get");
        const data = formatData(res, "code");

        yield put({
            type: types.EVENTS_PROCESS_CODE_SUCCEEDED,
            eventsProcessCode: data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.EVENTS_PROCESS_CODE_FAILED,
        });
    }
}


function* getEventsWarningCode () {
    try {
        const res = yield fetch(optionsApi.eventsWarningCode, {}, "get");
        const data = formatData(res, "code");

        yield put({
            type: types.EVENTS_WARNING_CODE_SUCCEEDED,
            eventsWarningCode: data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.EVENTS_WARNING_CODE_FAILED,
        });
    }
}


export default function* optionsSaga () {
    yield all({
        project: takeLeading(types.PROJECT_LIST_REQUESTED, getProjectList),
        role: takeLeading(types.ROLE_LIST_REQUESTED, getRoleList),
        device: takeLeading(types.DEVICE_LIST_REQUESTED, getDeviceList),
        processCode: takeLeading(types.PROCESS_CODE_REQUESTED, getProcessCode),
        runningModeCode: takeLeading(types.RUNNING_MODE_CODE_REQUESTED, getRunningModeCode),
        eventsWarningCode: takeLeading(types.EVENTS_WARNING_CODE_REQUESTED, getEventsWarningCode),
        eventsProcessCode: takeLeading(types.EVENTS_PROCESS_CODE_REQUESTED, getEventsProcessCode)
    });
}

