import React from "react";
import { Switch, Route } from "react-router-dom";
import App from "../App";
import Login from "../pages/Login";
import Page404 from "../pages/Page404";
import Page403 from "../pages/Page403";
import { requireAuth } from "../utils/requireAuth";
import SgLogin from "../pages/SgLogin";
import CmccLogin from "../pages/CmccLogin";

const IndexRoute = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/sg-login-no" component={SgLogin} />
            <Route exact path="/cmcc/:id/login" component={CmccLogin} />
            <Route exact path="/404" component={Page404} />
            <Route exact path="/403" component={Page403} />
            <Route path="/" component={requireAuth(App)} />
        </Switch>
    );
};


export default IndexRoute;
