import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: [],
    loading: false,
    total: 0,
    page: 0,
    pageSize: 20,
    params: {}
};

const usersList = produce((draft = init, action) => {
    switch (action.type) {
        case types.USERS_LIST_REQUESTED:
            draft.data = [];
            draft.loading = true;
            draft.total = 0;

            return draft;

        case types.USERS_LIST_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;
            draft.total = action.total;

            return draft;

        case types.USERS_LIST_FAILED:
            draft.loading = false;
            draft.data = [];
            draft.total = 0;

            return draft;

        case types.USERS_LIST_UPDATE_PAGE:
            draft.page = action.page;
            draft.pageSize = action.pageSize;

            return draft;

        case types.USERS_LIST_UPDATE_PARAMS:
            draft.params = {
                ...draft.params,
                ...action.params,
            };

            return draft;
        case types.USERS_LIST_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;
        default:
            return draft;
    }
});

export default usersList;
