import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { eventsApi } from "../../../api/http.config";
import { fetch } from "../../../utils";
import { message } from "antd";

const getEventsHandlingStore = s => s.events.handling;

const getParams = store => {
    return {
        size: store.size,
        page: store.page - 1,
        params: {
            fromDate: store.date ? store.date[0] : null,
            toDate: store.date ? store.date[1] : null,
            warningCode: store.warningCode,
            processingCode: store.processingCode,
            deviceCode: store.name
        }
    };
};

const formatData = (data = {}) => {
    const res = data.content ? data.content.map((item, key) => {
        return {
            ...item,
            device: item.device.name,
            eventProcessingMessage: item.eventProcessingMessage.name,
            warningMessage: item.warningMessage.name,
            key
        };
    }) : [];

    return {
        data: res,
        total: data.totalElements,
    };
};

function* getEventsHandling () {
    try {
        const store = yield select(getEventsHandlingStore);
        const { params, page, size } = yield getParams(store);

        const res = yield fetch(`${eventsApi.handling}?page=${page}&size=${size}`, params, "post");

        const { data, total } = formatData(res);

        yield put({
            type: types.EVENTS_HANDLING_SUCCEEDED,
            data,
            total
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.EVENTS_HANDLING_FAILED,
        });
    }
}

function* watchGetEventsHandling () {
    yield all({
        historyStatus: takeEvery(types.EVENTS_HANDLING_REQUESTED, getEventsHandling),
    });
}

export { watchGetEventsHandling };
