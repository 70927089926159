export const REMOTE_ALTERNATIVE_REQUESTED = "REMOTE_ALTERNATIVE_REQUESTED";
export const REMOTE_ALTERNATIVE_SUCCEEDED = "REMOTE_ALTERNATIVE_SUCCEEDED";
export const REMOTE_ALTERNATIVE_FAILED = "REMOTE_ALTERNATIVE_FAILED";
export const REMOTE_ALTERNATIVE_UPDATE_PARAMS_DEVICE = "REMOTE_ALTERNATIVE_UPDATE_PARAMS_DEVICE";
export const REMOTE_ALTERNATIVE_CLEAR_DATA = "REMOTE_ALTERNATIVE_CLEAR_DATA";

export const REMOTE_CLEANING_REQUESTED = "REMOTE_CLEANING_REQUESTED";
export const REMOTE_CLEANING_SUCCEEDED = "REMOTE_CLEANING_SUCCEEDED";
export const REMOTE_CLEANING_FAILED = "REMOTE_CLEANING_FAILED";
export const REMOTE_CLEANING_UPDATE_PARAMS_DEVICE = "REMOTE_CLEANING_UPDATE_PARAMS_DEVICE";
export const REMOTE_CLEANING_CLEAR_DATA = "REMOTE_CLEANING_CLEAR_DATA";

export const REMOTE_ELECTRIC_METER_REQUESTED = "REMOTE_ELECTRIC_METER_REQUESTED";
export const REMOTE_ELECTRIC_METER_SUCCEEDED = "REMOTE_ELECTRIC_METER_SUCCEEDED";
export const REMOTE_ELECTRIC_METER_FAILED = "REMOTE_ELECTRIC_METER_FAILED";
export const REMOTE_ELECTRIC_METER_UPDATE_PARAMS_DEVICE = "REMOTE_ELECTRIC_METER_UPDATE_PARAMS_DEVICE";
export const REMOTE_ELECTRIC_METER_CLEAR_DATA = "REMOTE_ELECTRIC_METER_CLEAR_DATA";

export const REMOTE_WARNING_HANDLE_REQUESTED = "REMOTE_WARNING_HANDLE_REQUESTED";
export const REMOTE_WARNING_HANDLE_SUCCEEDED = "REMOTE_WARNING_HANDLE_SUCCEEDED";
export const REMOTE_WARNING_HANDLE_FAILED = "REMOTE_WARNING_HANDLE_FAILED";
export const REMOTE_WARNING_HANDLE_UPDATE_PARAMS_DEVICE = "REMOTE_WARNING_HANDLE_UPDATE_PARAMS_DEVICE";
export const REMOTE_WARNING_HANDLE_CLEAR_DATA = "REMOTE_WARNING_HANDLE_CLEAR_DATA";

export const REMOTE_WARNING_SETTING_REQUESTED = "REMOTE_WARNING_SETTING_REQUESTED";
export const REMOTE_WARNING_SETTING_SUCCEEDED = "REMOTE_WARNING_SETTING_SUCCEEDED";
export const REMOTE_WARNING_SETTING_FAILED = "REMOTE_WARNING_SETTING_FAILED";
export const REMOTE_WARNING_SETTING_UPDATE_PARAMS_DEVICE = "REMOTE_WARNING_SETTING_UPDATE_PARAMS_DEVICE";
export const REMOTE_WARNING_SETTING_CLEAR_DATA = "REMOTE_WARNING_SETTING_CLEAR_DATA";

export const REMOTE_FRESH_AIR_REQUESTED = "REMOTE_FRESH_AIR_REQUESTED";
export const REMOTE_FRESH_AIR_SUCCEEDED = "REMOTE_FRESH_AIR_SUCCEEDED";
export const REMOTE_FRESH_AIR_FAILED = "REMOTE_FRESH_AIR_FAILED";
export const REMOTE_FRESH_AIR_UPDATE_PARAMS_DEVICE = "REMOTE_FRESH_AIR_UPDATE_PARAMS_DEVICE";
export const REMOTE_FRESH_AIR_CLEAR_DATA = "REMOTE_FRESH_AIR_CLEAR_DATA";

export const REMOTE_AUTO_MODE_REQUESTED = "REMOTE_AUTO_MODE_REQUESTED";
export const REMOTE_AUTO_MODE_SUCCEEDED = "REMOTE_AUTO_MODE_SUCCEEDED";
export const REMOTE_AUTO_MODE_FAILED = "REMOTE_AUTO_MODE_FAILED";
export const REMOTE_AUTO_MODE_UPDATE_PARAMS_DEVICE = "REMOTE_AUTO_MODE_UPDATE_PARAMS_DEVICE";
export const REMOTE_AUTO_MODE_CLEAR_DATA = "REMOTE_AUTO_MODE_CLEAR_DATA";

export const REMOTE_RUNNING_MODE_REQUESTED = "REMOTE_RUNNING_MODE_REQUESTED";
export const REMOTE_RUNNING_MODE_SUCCEEDED = "REMOTE_RUNNING_MODE_SUCCEEDED";
export const REMOTE_RUNNING_MODE_FAILED = "REMOTE_RUNNING_MODE_FAILED";
export const REMOTE_RUNNING_MODE_UPDATE_PARAMS_DEVICE = "REMOTE_RUNNING_MODE_UPDATE_PARAMS_DEVICE";
export const REMOTE_RUNNING_MODE_CLEAR_DATA = "REMOTE_RUNNING_MODE_CLEAR_DATA";
