import React from "react";
import { Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "../components/Loading";
import { requireAuth } from "../utils/requireAuth";
import Page404 from "../pages/Page404";

const Handling = loadable(() => import("../pages/events/Handling"), {
    fallback: <Loading />
});

const EventsRoute = () => {
    return (
        <Switch>
            <Route exact path="/events/handling" component={requireAuth(Handling)} />
            <Route component={Page404} />
        </Switch>
    );
};


export default EventsRoute;
