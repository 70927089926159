export const ROLE_LIST_REQUESTED = "ROLE_LIST_REQUESTED";
export const ROLE_LIST_SUCCEEDED = "ROLE_LIST_SUCCEEDED";
export const ROLE_LIST_FAILED = "ROLE_LIST_FAILED";

export const PROJECT_LIST_REQUESTED = "PROJECT_LIST_REQUESTED";
export const PROJECT_LIST_SUCCEEDED = "PROJECT_LIST_SUCCEEDED";
export const PROJECT_LIST_FAILED = "PROJECT_LIST_FAILED";

export const DEVICE_LIST_REQUESTED = "DEVICE_LIST_REQUESTED";
export const DEVICE_LIST_SUCCEEDED = "DEVICE_LIST_SUCCEEDED";
export const DEVICE_LIST_FAILED = "DEVICE_LIST_FAILED";

export const PROCESS_CODE_REQUESTED = "PROCESS_CODE_REQUESTED";
export const PROCESS_CODE_SUCCEEDED = "PROCESS_CODE_SUCCEEDED";
export const PROCESS_CODE_FAILED = "PROCESS_CODE_FAILED";

export const RUNNING_MODE_CODE_REQUESTED = "RUNNING_MODE_CODE_REQUESTED";
export const RUNNING_MODE_CODE_SUCCEEDED = "RUNNING_MODE_CODE_SUCCEEDED";
export const RUNNING_MODE_CODE_FAILED = "RUNNING_MODE_CODE_FAILED";


export const EVENTS_PROCESS_CODE_REQUESTED = "EVENTS_PROCESS_CODE_REQUESTED";
export const EVENTS_PROCESS_CODE_SUCCEEDED = "EVENTS_PROCESS_CODE_SUCCEEDED";
export const EVENTS_PROCESS_CODE_FAILED = "EVENTS_PROCESS_CODE_FAILED";


export const EVENTS_WARNING_CODE_REQUESTED = "EVENTS_WARNING_CODE_REQUESTED";
export const EVENTS_WARNING_CODE_SUCCEEDED = "EVENTS_WARNING_CODE_SUCCEEDED";
export const EVENTS_WARNING_CODE_FAILED = "EVENTS_WARNING_CODE_FAILED";


