import React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "../components/Loading";
import loadable from "@loadable/component";
import { requireAuth } from "../utils/requireAuth";
import Page404 from "../pages/Page404";

const UsersList = loadable(() => import ("../pages/users/UsersList"), {
    callback: <Loading />
});


const UsersRoute = () => {
    return (
        <Switch>
            <Route path="/users/list" component={requireAuth(UsersList)} />
            <Route component={Page404} />
        </Switch>
    );
};


export default UsersRoute;
