import instance from "../api/instance";

export const fetch = (url, data, method = "post") => {
    const config = {
        url,
        method
    };
    if (method === "get") {
        config.params = data;
    } else {
        config.data = data;
    }
    return instance
        .request(config)
        .then(res => {
            return res;
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const findItemByName = (name, obj) => {
    for (let i in obj) {
        if (obj.hasOwnProperty(i) && name === obj[i].name) {
            return obj[i];
        }
    }
};

export const findKeyByValue = (value, obj) => {
    for (let i in obj) {
        if (obj.hasOwnProperty(i) && value === obj[i]) {
            return i;
        }
    }
};

export const debounce = (func, delay = 500, timer = null) => {
    return (...args) => {

        clearInterval(timer);

        timer = setTimeout(() => func(...args), delay);
    };
};

export const formatCompany = company => company.split("-");

const altDateMap = {
    0: "不轮替",
    32: "单号",
    33: "双号",
    48: "星期日",
    49: "星期一",
    50: "星期二",
    51: "星期三",
    52: "星期四",
    53: "星期五",
    54: "星期六",
};

export const getAltDateDesc = code => {
    if (code <= 25 && code > 0) {
        return `${code}日`;
    }

    return altDateMap[code];
};
