import React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "../components/Loading";
import loadable from "@loadable/component";
import { requireAuth } from "../utils/requireAuth";
import Page404 from "../pages/Page404";

const ProjectList = loadable(() => import ("../pages/project/ProjectList"), {
    callback: <Loading />
});


const ProjectRoute = () => {
    return (
        <Switch>
            <Route path="/project/list" component={requireAuth(ProjectList)} />
            <Route component={Page404} />
        </Switch>
    );
};


export default ProjectRoute;
