import React, { useEffect, useState } from "react";
import LoginForm from "../components/login/LoginForm";
import BeianFooter from "../components/BeianFooter";

const CmccLogin = props => {
    const codes = ["01", "02", "03", "04", "05", "0598"];
    const [code, setCode] = useState("");

    useEffect(() => {
        const pathname = props.location.pathname;
        const pathnameArr = pathname.split("/");
        const len = pathnameArr.length;

        if (pathnameArr[len - 1] === "login" && pathnameArr[len - 3] === "cmcc" && codes.includes(pathnameArr[len - 2])) {
            setCode(`CMCC_${pathnameArr[len - 2]}`);
        }

    }, []);

    return (
        <div className="login">
            <LoginForm
                className="login-form"
                user={code}
                pw={code}
            />
            <BeianFooter />
        </div>
    );
};


export default CmccLogin;
