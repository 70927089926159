import { all } from "redux-saga/effects";
import { watchGetQueryingRunning } from "./runningStatus";
import { watchGetQueryingRealtime } from "./realtime";
import { watchGetQueryingRealtimeStatus } from "./realtimeStatus";
import { watchGetQueryingHistoryStatus } from "./historyStatus";
import { watchGetPowerQuerying } from "./powerQuerying";

export default function* queryingSaga () {
    yield all({
        runningStatus: watchGetQueryingRunning(),
        realtime: watchGetQueryingRealtime(),
        realtimeStatus: watchGetQueryingRealtimeStatus(),
        historyStatus: watchGetQueryingHistoryStatus(),
        powerQuerying: watchGetPowerQuerying(),
    });
}
