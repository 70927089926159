import React from "react";
import { Spin } from "antd";

const Loading = () => {
    return (
        <div style={{ width: "100%", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spin size="large" />
        </div>
    );
};

export default Loading;
