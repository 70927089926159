import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const Page404 = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push("/");
    };

    return (
        <Result
            status="404"
            title="404"
            subTitle="对不起，页面不存在。"
            extra={<Button onClick={handleClick} type="primary">回到主页</Button>}
        />
    );
};


export default Page404;
