import React from "react";
import { Switch, Route } from "react-router-dom";
import EventsRoute from "./EventsRoute";
import UsersRoute from "./UsersRoute";
import EquipmentRoute from "./EquipmentRoute";
import ProjectRoute from "./ProjectRoute";
import QueryingRoute from "./QueryingRoute";
import RemoteRoute from "./RemoteRoute";
import Home from "../pages/Home";
import Page404 from "../pages/Page404";

const MainRoute = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/events" component={EventsRoute} />
            <Route path="/users" component={UsersRoute} />
            <Route path="/equipment" component={EquipmentRoute} />
            <Route path="/querying" component={QueryingRoute} />
            <Route path="/project" component={ProjectRoute} />
            <Route path="/remote" component={RemoteRoute} />
            <Route component={Page404} />
        </Switch>
    );
};


export default MainRoute;
