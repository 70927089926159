import { all } from "redux-saga/effects";
import { watchGetRemoteAlternative } from "./alternative";
import { watchGetRemoteCleaning } from "./cleaning";
import { watchGetRemoteElectric } from "./electricMeter";
import { watchGetRemoteWarningHandle } from "./warningHandle";
import { watchGetRemoteWarningSetting } from "./warningSetting";
import { watchGetRemoteAutoMode } from "./autoMode";
import { watchGetRemoteFreshAir } from "./freshAir";
import { watchGetRemoteRunningMode } from "./runningMode";

export default function* remoteSaga () {
    yield all({
        cleaning: watchGetRemoteCleaning(),
        alternative: watchGetRemoteAlternative(),
        electric: watchGetRemoteElectric(),
        warningHandle: watchGetRemoteWarningHandle(),
        warningSetting: watchGetRemoteWarningSetting(),
        autoMode: watchGetRemoteAutoMode(),
        freshAir: watchGetRemoteFreshAir(),
        runningMode: watchGetRemoteRunningMode(),
    });
}
