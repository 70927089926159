import { takeEvery, put, all, select } from "redux-saga/effects";
import * as types from "../../action-types";
import { queryingApi } from "../../../api/http.config";
import { fetch, getAltDateDesc } from "../../../utils";
import { message } from "antd";

const getRealtimeStore = s => s.querying.realtime;
const getDeviceOptionStore = s => s.options.device;

const getParams = (store, deviceList) => {
    const deviceName = deviceList[store.device].communicationCardNo;
    return {
        deviceCode: store.device,
        deviceName,
    };
};

const formatData = (data = {}) => {
    return {
        ...data,
        mode: data.mode?.name,
        airStatus:data.airStatus? "开启":"关闭",
        fanStatus:data.fanStatus? "开启":"关闭",
        valveStatus:data.valveStatus? "开启":"关闭",
        altDate: getAltDateDesc(+data.altDate),
    };
};

function* getRealtime () {
    try {
        const store = yield select(getRealtimeStore);
        const deviceList = yield select(getDeviceOptionStore);
        const params = yield getParams(store, deviceList);
        let data = yield fetch(queryingApi.realtime, params, "get");
        data = formatData(data);
        yield put({
            type: types.QUERYING_REALTIME_SUCCEEDED,
            data,
        });
    } catch (e) {
        message.error(e.message);
        yield put({
            type: types.QUERYING_REALTIME_FAILED,
        });
    }
}

function* watchGetQueryingRealtime () {
    yield all({
        realtime: takeEvery(types.QUERYING_REALTIME_REQUESTED, getRealtime),
    });
}

export { watchGetQueryingRealtime };
