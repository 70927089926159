export const QUERYING_RUNNING_REQUESTED = "QUERYING_RUNNING_REQUESTED";
export const QUERYING_RUNNING_SUCCEEDED = "QUERYING_RUNNING_SUCCEEDED";
export const QUERYING_RUNNING_FAILED = "QUERYING_RUNNING_FAILED";
export const QUERYING_RUNNING_UPDATE_PARAMS_DATE = "QUERYING_RUNNING_UPDATE_PARAMS_DATE";
export const QUERYING_RUNNING_UPDATE_PARAMS_DEVICE = "QUERYING_RUNNING_UPDATE_PARAMS_DEVICE";
export const QUERYING_RUNNING_CLEAR_DATA = "QUERYING_RUNNING_CLEAR_DATA";

export const QUERYING_REALTIME_REQUESTED = "QUERYING_REALTIME_REQUESTED";
export const QUERYING_REALTIME_SUCCEEDED = "QUERYING_REALTIME_SUCCEEDED";
export const QUERYING_REALTIME_FAILED = "QUERYING_REALTIME_FAILED";
export const QUERYING_REALTIME_UPDATE_PARAMS_DEVICE = "QUERYING_REALTIME_UPDATE_PARAMS_DEVICE";
export const QUERYING_REALTIME_CLEAR_DATA = "QUERYING_REALTIME_CLEAR_DATA";

export const QUERYING_REALTIME_STATUS_REQUESTED = "QUERYING_REALTIME_STATUS_REQUESTED";
export const QUERYING_REALTIME_STATUS_SUCCEEDED = "QUERYING_REALTIME_STATUS_SUCCEEDED";
export const QUERYING_REALTIME_STATUS_FAILED = "QUERYING_REALTIME_STATUS_FAILED";
export const QUERYING_REALTIME_STATUS_UPDATE_PAGE = "QUERYING_REALTIME_STATUS_UPDATE_PAGE";
export const QUERYING_REALTIME_STATUS_CLEAR_DATA = "QUERYING_REALTIME_STATUS_CLEAR_DATA";
export const QUERYING_REALTIME_STATUS_PARAMS_DEVICE = "QUERYING_REALTIME_STATUS_PARAMS_DEVICE";

export const QUERYING_HISTORY_STATUS_REQUESTED = "QUERYING_HISTORY_STATUS_REQUESTED";
export const QUERYING_HISTORY_STATUS_SUCCEEDED = "QUERYING_HISTORY_STATUS_SUCCEEDED";
export const QUERYING_HISTORY_STATUS_FAILED = "QUERYING_HISTORY_STATUS_FAILED";
export const QUERYING_HISTORY_STATUS_UPDATE_PAGE = "QUERYING_HISTORY_STATUS_UPDATE_PAGE";
export const QUERYING_HISTORY_STATUS_UPDATE_DATE = "QUERYING_HISTORY_STATUS_UPDATE_DATE";
export const QUERYING_HISTORY_STATUS_UPDATE_DEVICE = "QUERYING_HISTORY_STATUS_UPDATE_DEVICE";
export const QUERYING_HISTORY_STATUS_CLEAR_DATA = "QUERYING_HISTORY_STATUS_CLEAR_DATA";

export const QUERYING_POWER_QUERYING_REQUESTED = "QUERYING_POWER_QUERYING_REQUESTED";
export const QUERYING_POWER_QUERYING_SUCCEEDED = "QUERYING_POWER_QUERYING_SUCCEEDED";
export const QUERYING_POWER_QUERYING_FAILED = "QUERYING_POWER_QUERYING_FAILED";
export const QUERYING_POWER_QUERYING_UPDATE_DATE = "QUERYING_POWER_QUERYING_UPDATE_DATE";
export const QUERYING_POWER_QUERYING_UPDATE_DEVICE = "QUERYING_POWER_QUERYING_UPDATE_DEVICE";
export const QUERYING_POWER_QUERYING_CLEAR_DATA = "QUERYING_POWER_QUERYING_CLEAR_DATA";
