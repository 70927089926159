import React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "../components/Loading";
import loadable from "@loadable/component";
import { requireAuth } from "../utils/requireAuth";
import Page404 from "../pages/Page404";

const SelfCleaning = loadable(() => import ("../pages/remote/SelfCleaning"), {
    callback: <Loading />
});

const Alternative = loadable(() => import ("../pages/remote/Alternative"), {
    callback: <Loading />
});

const ElectricMeter = loadable(() => import("../pages/remote/ElectricMeter"), {
    callback: <Loading />
});
const WarningHandle = loadable(() => import("../pages/remote/WarningHandle"), {
    callback: <Loading />
});
const WarningSetting = loadable(() => import("../pages/remote/WarningSetting"), {
    callback: <Loading />
});
const FreshAir = loadable(() => import("../pages/remote/FreshAir"), {
    callback: <Loading />
});
const AutoMode = loadable(() => import("../pages/remote/AutoMode"), {
    callback: <Loading />
});
const Realtime = loadable(() => import ("../pages/querying/Realtime"), {
    callback: <Loading />
});
const RunningMode = loadable(() => import ("../pages/remote/RunningMode"), {
    callback: <Loading />
});

const RemoteRoute = () => {
    return (
        <Switch>
            <Route path="/remote/cleaning" component={requireAuth(SelfCleaning)} />
            <Route path="/remote/alternative" component={requireAuth(Alternative)} />
            <Route path="/remote/electric-meter" component={requireAuth(ElectricMeter)} />
            <Route path="/remote/warning-handle" component={requireAuth(WarningHandle)} />
            <Route path="/remote/warning-setting" component={requireAuth(WarningSetting)} />
            <Route path="/remote/querying" component={requireAuth(Realtime)} />
            <Route path="/remote/fresh-air" component={requireAuth(FreshAir)} />
            <Route path="/remote/auto-mode" component={requireAuth(AutoMode)} />
            <Route path="/remote/running-mode" component={requireAuth(RunningMode)} />
            <Route component={Page404} />
        </Switch>
    );
};


export default RemoteRoute;
