import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: {},
    loading: false,
    device: undefined,
};

const realtime = produce((draft = init, action) => {
    switch (action.type) {
        case types.QUERYING_REALTIME_REQUESTED:
            draft.data = {};
            draft.loading = true;

            return draft;

        case types.QUERYING_REALTIME_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;

            return draft;

        case types.QUERYING_REALTIME_FAILED:
            draft.loading = false;
            draft.data = {};

            return draft;

        case types.QUERYING_REALTIME_UPDATE_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.QUERYING_REALTIME_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default realtime;
