import * as types from "../../action-types";
import produce from "immer";


const init = {
    timingData: {},
    bookingData: [],
    loading: false,
    device: undefined,
    created:"",
};

const cleaning = produce((draft = init, action) => {
    switch (action.type) {
        case types.REMOTE_CLEANING_REQUESTED:
            draft.timingData = {};
            draft.bookingData = [];
            draft.loading = true;
            draft.created = "";

            return draft;

        case types.REMOTE_CLEANING_SUCCEEDED:
            draft.loading = false;
            draft.timingData = action.timingData;
            draft.bookingData = action.bookingData;
            draft.created = action.created;

            return draft;

        case types.REMOTE_CLEANING_FAILED:
            draft.loading = false;
            draft.timingData = {};
            draft.bookingData = [];
            draft.created = "";

            return draft;

        case types.REMOTE_CLEANING_UPDATE_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.REMOTE_CLEANING_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default cleaning;
