import axios from "axios";
import host from "./http.config";

const instance = axios.create({
    baseURL: host,
    headers: {
        withCredentials: true,
    },
    timeout: 60000,
    validateStatus (status) {
        return status >= 200 && status <= 500;
    },
});

instance.interceptors.request.use(request => {
    const token = sessionStorage.getItem("token") || "";
    request.headers["Authorization"] = "Bearer " + token;

    return request;
});

instance.interceptors.response.use(
    res => {
        try {
            switch (res.status) {
                case 400:
                    return Promise.reject({ message: "400 Bad Request" });
                case 401:
                    return window.location.replace("/login");
                case 403:
                    return window.location.replace("/login");
                case 409:
                    return Promise.reject(res.data);
                case 500:
                    return Promise.reject(res.data);
                case 404:
                    return Promise.reject({ message: "404 NOT FOUND" });
                case 200:
                case 201:
                    return Promise.resolve(res.data);
                default:
                    return res;
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    err => {
        return Promise.reject(err);
    },
);

export default instance;
