import React, { useState } from "react";
import { Button, Modal } from "antd";
import ChangePwdModal from "./ChangePwdModal";
import { fetch } from "../../utils";
import { usersApi } from "../../api/http.config";
import PropTypes from "prop-types";

const ChangePwdBtn = props => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = values => {
        setLoading(true);
        const params = {
            username: values.username,
            password: values.password,
            currentPassword: values.currentPassword,
        };

        fetch(usersApi.password, params, "put")
            .then(() => {
                Modal.success({
                    content: "密码修改成功！"
                });
            })
            .catch(e => {
                Modal.error({
                    content: "密码修改失败！"
                });
                console.log(e.message);
            })
            .finally(() => {
                setVisible(false);
                setLoading(false);
            });


    };

    const handleClick = () => {
        setVisible(true);
    };

    return (
        <>
            {
                props.type === "link" ? (
                    <Button onClick={handleClick} type="link">修改密码</Button>
                ) : (
                    <span
                        onClick={handleClick}
                    >
                        修改密码
                    </span>
                )
            }

            <ChangePwdModal
                visible={visible}
                onCancel={onCancel}
                onOk={onOk}
                loading={loading}
                username={props.username}
            />
        </>
    );
};


export default ChangePwdBtn;

ChangePwdBtn.propTypes = {
    username: PropTypes.string,
    type: PropTypes.string,
};

