const httpConfig = {
    development: "",
    test: "",
    production: `${location.origin}:8080`,
};

export const authApi = {
    auth: "/authenticate",
    validate: "/validateToken",
    invalidate: "/invalidateToken",
};

export const usersApi = {
    user: "/user",
    password: "/user/password",
};

export const equipApi = {
    device: "/device",
};

export const eventsApi = {
    handling: "/event",
    eventProcess: "/eventProcess",
};

export const optionsApi = {
    project: "/project",
    role: "/role",
    processCode: "/warningProcessCode",
    runningModeCode: "/runningModeCode",
    eventsProcessCode: "/eventProcessCode",
    eventsWarningCode: "/warningCode",
};

export const queryingApi = {
    runningStatus: "/query/runningStatus",
    realtimeStatus: "/query/latestStatus",
    historyStatus: "/query/dailyStatus",
    historyExport: "/query/exportStatus",
    realtime: "/query/realTime",
    alternative: "/query/alternativeParameters",
    cleaning: "/query/selfCleaning",
    electric: "/query/meterParameter",
    warningHandle: "/query/processParameters",
    warningSetting: "/query/warningSetting",
    freshAir: "/query/fanParameters",
    autoMode: "/query/autoParameters",
    runningMode: "/query/runningMode",
    power: "/query/powerStatus",
};

export const propertyApi = {
    cleaning: "/iot/property/selfCleaning",
    alternative: "/iot/property/alternativeParameters",
    electric: "/iot/property/metersNumber",
    warningHandle: "/iot/property/processParameters",
    warningSetting: "/iot/property/warningSetting",
    freshAir: "/iot/property/fanParameters",
    autoMode: "/iot/property/autoParameters",
    runningMode: "/iot/property/runningMode",
};

export default httpConfig[process.env.NODE_ENV];
