import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: {},
    loading: false,
    device: undefined,
    created: ""
};

const runningMode = produce((draft = init, action) => {
    switch (action.type) {
        case types.REMOTE_RUNNING_MODE_REQUESTED:
            draft.data = {};
            draft.created = "";
            draft.loading = true;

            return draft;

        case types.REMOTE_RUNNING_MODE_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;
            draft.created = action.created;

            return draft;

        case types.REMOTE_RUNNING_MODE_FAILED:
            draft.loading = false;
            draft.data = {};
            draft.created = "";

            return draft;

        case types.REMOTE_RUNNING_MODE_UPDATE_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.REMOTE_RUNNING_MODE_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default runningMode;
