export const getFormLayout = (span = 6) => {
    return {
        labelCol: {
            span
        },
        wrapperCol: {
            span: 24 - span
        }
    };
};

export const validateNumber = number => {
    if (typeof +number === "number") {
        return {
            validateStatus: "success",
            errorMsg: null
        };
    }

    return {
        validateStatus:"error",
        errorMsg: "请输入正确数字"
    }
};
