import React from "react";
import LoginForm from "../components/login/LoginForm";
import BeianFooter from "../components/BeianFooter";

const SgLogin = () => {
    const code = "CMCC_0594";

    return (
        <div className="login">
            <LoginForm
                className="login-form"
                user={code}
                pw={code}
            />
            <BeianFooter />
        </div>
    );
};

export default SgLogin;
