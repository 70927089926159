import * as types from "../../action-types";
import produce from "immer";


const init = {
    data: {},
    loading: false,
    device: undefined
};

const autoMode = produce((draft = init, action) => {
    switch (action.type) {
        case types.REMOTE_AUTO_MODE_REQUESTED:
            draft.data = {};
            draft.loading = true;

            return draft;

        case types.REMOTE_AUTO_MODE_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;

            return draft;

        case types.REMOTE_AUTO_MODE_FAILED:
            draft.loading = false;
            draft.data = {};

            return draft;

        case types.REMOTE_AUTO_MODE_UPDATE_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.REMOTE_AUTO_MODE_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default autoMode;
