import { combineReducers } from "redux";
import runningStatus from "./runningStatus";
import realtime from "./realtime";
import realtimeStatus from "./realtimeStatus";
import historyStatus from "./historyStatus";
import powerQuerying from "./powerQuerying";

const querying = combineReducers({
    runningStatus,
    realtime,
    realtimeStatus,
    historyStatus,
    powerQuerying,
});

export default querying;
