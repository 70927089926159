import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const Page403 = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push("/");
    };
    return (
        <Result
            status="403"
            title="403"
            subTitle="对不起，您没有权限访问此页面。"
            extra={<Button onClick={handleClick} type="primary">回到主页</Button>}
        />
    );
};


export default Page403;
