import React from "react";
import { Modal } from "antd";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import { connect } from "react-redux";
import actions from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
import { authApi } from "../../api/http.config";
import { fetch } from "../../utils";

const Logout = props => {
    const history = useHistory();

    const handleClick = () => {
        Modal.confirm({
            title: "确定要退出登录吗？",
            icon: <ExclamationCircleOutlined />,
            okText: "确认",
            cancelText: "取消",
            onOk () {
                logout();
            }
        });
    };

    const logout = () => {
        props.log_out();
        fetch(authApi.invalidate, null, "get")
            .then(() => {
                sessionStorage.removeItem("token");
                history.push("/login");
            })
            .catch(e=>{
                console.log(e);
            });

    };


    return (
        <div onClick={handleClick}>退 出</div>
    );
};


export default connect(null, actions)(Logout);
