import { combineReducers } from "redux";
import usersList from "./usersList";


const users = combineReducers({
    usersList,

});

export default users;
