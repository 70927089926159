import * as types from "../action-types";
import produce from "immer";


const init = {
    project: {},
    role: {},
    projectLoading: false,
    device: {},
    processCode: {},
    parentProject: {},
    runningModeCode:{},
    eventsProcessCode:{},
    eventsWarningCode:{},
};

const options = produce((draft = init, action) => {
    switch (action.type) {
        case types.PROJECT_LIST_REQUESTED:
            draft.project = {};
            draft.parentProject = {};
            draft.projectLoading = true;

            return draft;

        case types.PROJECT_LIST_SUCCEEDED:
            draft.project = action.data;
            draft.parentProject = action.parentData;
            draft.projectLoading = false;

            return draft;

        case types.PROJECT_LIST_FAILED:
            draft.projectLoading = false;

            return draft;

        case types.ROLE_LIST_REQUESTED:
            draft.role = {};

            return draft;

        case types.ROLE_LIST_SUCCEEDED:
            draft.role = action.data;

            return draft;

        case types.ROLE_LIST_FAILED:

            return draft;
        case types.DEVICE_LIST_REQUESTED:
            draft.device = {};

            return draft;

        case types.DEVICE_LIST_SUCCEEDED:
            draft.device = action.data;

            return draft;

        case types.DEVICE_LIST_FAILED:

            return draft;

        case types.PROCESS_CODE_REQUESTED:
            draft.processCode = {};

            return draft;

        case types.PROCESS_CODE_SUCCEEDED:
            draft.processCode = action.processCode;


            return draft;

        case types.PROCESS_CODE_FAILED:


            return draft;

        case types.RUNNING_MODE_CODE_REQUESTED:
            draft.runningModeCode = {};

            return draft;

        case types.RUNNING_MODE_CODE_SUCCEEDED:
            draft.runningModeCode = action.runningModeCode;


            return draft;

        case types.RUNNING_MODE_CODE_FAILED:


            return draft;

        case types.EVENTS_PROCESS_CODE_REQUESTED:
            draft.eventsProcessCode = {};

            return draft;

        case types.EVENTS_PROCESS_CODE_SUCCEEDED:
            draft.eventsProcessCode = action.eventsProcessCode;


            return draft;

        case types.EVENTS_PROCESS_CODE_FAILED:


            return draft;

        case types.EVENTS_WARNING_CODE_REQUESTED:
            draft.eventsWarningCode = {};

            return draft;

        case types.EVENTS_WARNING_CODE_SUCCEEDED:
            draft.eventsWarningCode = action.eventsWarningCode;


            return draft;

        case types.EVENTS_WARNING_CODE_FAILED:


            return draft;

        default:
            return draft;
    }
});

export default options;
