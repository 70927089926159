import * as types from "../../action-types";
import produce from "immer";
import moment from "moment";

const init = {
    data: [],
    loading: false,
    date: moment().add(-1, "day").format("YYYY-MM-DD"),
    device: undefined
};

const runningStatus = produce((draft = init, action) => {
    switch (action.type) {
        case types.QUERYING_RUNNING_REQUESTED:
            draft.data = [];
            draft.loading = true;

            return draft;

        case types.QUERYING_RUNNING_SUCCEEDED:
            draft.loading = false;
            draft.data = action.data;

            return draft;

        case types.QUERYING_RUNNING_FAILED:
            draft.loading = false;
            draft.data = [];

            return draft;

        case types.QUERYING_RUNNING_UPDATE_PARAMS_DATE:
            draft.date = action.date;

            return draft;

        case types.QUERYING_RUNNING_UPDATE_PARAMS_DEVICE:
            draft.device = action.device;

            return draft;

        case types.QUERYING_RUNNING_CLEAR_DATA:
            draft = {
                ...init
            };
            return draft;

        default:
            return draft;
    }
});

export default runningStatus;
